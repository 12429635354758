import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _85c79f52 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _b27ebebc = () => interopDefault(import('../pages/action/index.vue' /* webpackChunkName: "pages/action/index" */))
const _1d9a10ac = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _75ddef76 = () => interopDefault(import('../pages/audit/index.vue' /* webpackChunkName: "pages/audit/index" */))
const _117aa9eb = () => interopDefault(import('../pages/deviation/index.vue' /* webpackChunkName: "pages/deviation/index" */))
const _b70e7b5a = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _6e02aab3 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _2c62518a = () => interopDefault(import('../pages/offline.vue' /* webpackChunkName: "pages/offline" */))
const _32bcadc7 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _4fc3169f = () => interopDefault(import('../pages/organization/index.vue' /* webpackChunkName: "pages/organization/index" */))
const _506a06e2 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _0d995bc0 = () => interopDefault(import('../pages/report/index.vue' /* webpackChunkName: "pages/report/index" */))
const _07a33cd8 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _d5bc7bea = () => interopDefault(import('../pages/styles.vue' /* webpackChunkName: "pages/styles" */))
const _4b2d9b20 = () => interopDefault(import('../pages/suggestion/index.vue' /* webpackChunkName: "pages/suggestion/index" */))
const _09e74171 = () => interopDefault(import('../pages/task/index.vue' /* webpackChunkName: "pages/task/index" */))
const _10f39aa0 = () => interopDefault(import('../pages/unit/index.vue' /* webpackChunkName: "pages/unit/index" */))
const _3847f325 = () => interopDefault(import('../pages/verify/index.vue' /* webpackChunkName: "pages/verify/index" */))
const _8cf325b6 = () => interopDefault(import('../pages/account/organizations.vue' /* webpackChunkName: "pages/account/organizations" */))
const _046136a6 = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _12c118cb = () => interopDefault(import('../pages/account/security.vue' /* webpackChunkName: "pages/account/security" */))
const _1644de09 = () => interopDefault(import('../pages/audit/plan/index.vue' /* webpackChunkName: "pages/audit/plan/index" */))
const _5fb8da88 = () => interopDefault(import('../pages/organization/billing.vue' /* webpackChunkName: "pages/organization/billing" */))
const _0645feb0 = () => interopDefault(import('../pages/organization/companies.vue' /* webpackChunkName: "pages/organization/companies" */))
const _8b221cc8 = () => interopDefault(import('../pages/organization/events.vue' /* webpackChunkName: "pages/organization/events" */))
const _6797d652 = () => interopDefault(import('../pages/organization/groups.vue' /* webpackChunkName: "pages/organization/groups" */))
const _2419a54a = () => interopDefault(import('../pages/organization/job.vue' /* webpackChunkName: "pages/organization/job" */))
const _a479fbb4 = () => interopDefault(import('../pages/organization/members.vue' /* webpackChunkName: "pages/organization/members" */))
const _d801e940 = () => interopDefault(import('../pages/organization/subscription.vue' /* webpackChunkName: "pages/organization/subscription" */))
const _a6332e5e = () => interopDefault(import('../pages/password/activate/index.vue' /* webpackChunkName: "pages/password/activate/index" */))
const _2edebfc1 = () => interopDefault(import('../pages/password/forgot/index.vue' /* webpackChunkName: "pages/password/forgot/index" */))
const _50d17407 = () => interopDefault(import('../pages/password/reset/index.vue' /* webpackChunkName: "pages/password/reset/index" */))
const _eb14eebe = () => interopDefault(import('../pages/task/group/index.vue' /* webpackChunkName: "pages/task/group/index" */))
const _48f045da = () => interopDefault(import('../pages/unit/category/index.vue' /* webpackChunkName: "pages/unit/category/index" */))
const _d596cae6 = () => interopDefault(import('../pages/action/_id/index.vue' /* webpackChunkName: "pages/action/_id/index" */))
const _801b0ea0 = () => interopDefault(import('../pages/audit/_id/index.vue' /* webpackChunkName: "pages/audit/_id/index" */))
const _d4725154 = () => interopDefault(import('../pages/deviation/_id/index.vue' /* webpackChunkName: "pages/deviation/_id/index" */))
const _23d65d0b = () => interopDefault(import('../pages/report/_id/index.vue' /* webpackChunkName: "pages/report/_id/index" */))
const _88f8d54a = () => interopDefault(import('../pages/suggestion/_id/index.vue' /* webpackChunkName: "pages/suggestion/_id/index" */))
const _05a6759b = () => interopDefault(import('../pages/unit/_id/index.vue' /* webpackChunkName: "pages/unit/_id/index" */))
const _5dc5cf59 = () => interopDefault(import('../pages/report/_id/_procedure/a/_appendix/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/a/_appendix/index" */))
const _80ca62de = () => interopDefault(import('../pages/report/_id/_procedure/_content/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/_content/index" */))
const _46dc968e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _85c79f52,
    name: "account"
  }, {
    path: "/action",
    component: _b27ebebc,
    name: "action"
  }, {
    path: "/archive",
    component: _1d9a10ac,
    name: "archive"
  }, {
    path: "/audit",
    component: _75ddef76,
    name: "audit"
  }, {
    path: "/deviation",
    component: _117aa9eb,
    name: "deviation"
  }, {
    path: "/login",
    component: _b70e7b5a,
    name: "login"
  }, {
    path: "/logout",
    component: _6e02aab3,
    name: "logout"
  }, {
    path: "/offline",
    component: _2c62518a,
    name: "offline"
  }, {
    path: "/onboarding",
    component: _32bcadc7,
    name: "onboarding"
  }, {
    path: "/organization",
    component: _4fc3169f,
    name: "organization"
  }, {
    path: "/register",
    component: _506a06e2,
    name: "register"
  }, {
    path: "/report",
    component: _0d995bc0,
    name: "report"
  }, {
    path: "/search",
    component: _07a33cd8,
    name: "search"
  }, {
    path: "/styles",
    component: _d5bc7bea,
    name: "styles"
  }, {
    path: "/suggestion",
    component: _4b2d9b20,
    name: "suggestion"
  }, {
    path: "/task",
    component: _09e74171,
    name: "task"
  }, {
    path: "/unit",
    component: _10f39aa0,
    name: "unit"
  }, {
    path: "/verify",
    component: _3847f325,
    name: "verify"
  }, {
    path: "/account/organizations",
    component: _8cf325b6,
    name: "account-organizations"
  }, {
    path: "/account/password",
    component: _046136a6,
    name: "account-password"
  }, {
    path: "/account/security",
    component: _12c118cb,
    name: "account-security"
  }, {
    path: "/audit/plan",
    component: _1644de09,
    name: "audit-plan"
  }, {
    path: "/organization/billing",
    component: _5fb8da88,
    name: "organization-billing"
  }, {
    path: "/organization/companies",
    component: _0645feb0,
    name: "organization-companies"
  }, {
    path: "/organization/events",
    component: _8b221cc8,
    name: "organization-events"
  }, {
    path: "/organization/groups",
    component: _6797d652,
    name: "organization-groups"
  }, {
    path: "/organization/job",
    component: _2419a54a,
    name: "organization-job"
  }, {
    path: "/organization/members",
    component: _a479fbb4,
    name: "organization-members"
  }, {
    path: "/organization/subscription",
    component: _d801e940,
    name: "organization-subscription"
  }, {
    path: "/password/activate",
    component: _a6332e5e,
    name: "password-activate"
  }, {
    path: "/password/forgot",
    component: _2edebfc1,
    name: "password-forgot"
  }, {
    path: "/password/reset",
    component: _50d17407,
    name: "password-reset"
  }, {
    path: "/task/group",
    component: _eb14eebe,
    name: "task-group"
  }, {
    path: "/unit/category",
    component: _48f045da,
    name: "unit-category"
  }, {
    path: "/action/:id",
    component: _d596cae6,
    name: "action-id"
  }, {
    path: "/audit/:id",
    component: _801b0ea0,
    name: "audit-id"
  }, {
    path: "/deviation/:id",
    component: _d4725154,
    name: "deviation-id"
  }, {
    path: "/report/:id",
    component: _23d65d0b,
    name: "report-id"
  }, {
    path: "/suggestion/:id",
    component: _88f8d54a,
    name: "suggestion-id"
  }, {
    path: "/unit/:id",
    component: _05a6759b,
    name: "unit-id"
  }, {
    path: "/report/:id/:procedure/a/:appendix",
    component: _5dc5cf59,
    name: "report-id-procedure-a-appendix"
  }, {
    path: "/report/:id/:procedure/:content",
    component: _80ca62de,
    name: "report-id-procedure-content"
  }, {
    path: "/",
    component: _46dc968e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
